import React from "react"
import SendIcon from "../images/svg/send.svg"
import { useTranslation } from "react-i18next"
import { Field } from "formik"
import ProgressButton from "./button"

export default function Email(props) {
  const { t } = useTranslation()
  return (
    <div>
      <div className="relative rounded-full shadow">
        <Field
          type="email"
          name="email"
          placeholder={t("hintEmail")}
          className={`${
            props.error
              ? "border-red-400 focus:border-red-400"
              : "border-transparent focus:border-primary40"
          } transition-colors w-full h-14 py-2 pl-5 pr-36 text-neutral20 placeholder-neutral20 text-xs border-2 border-transparent rounded-full focus:ring-transparent focus:border-transparent`}
        />
        <div className="absolute transform -translate-y-1/2 top-1/2 right-3">
          <ProgressButton loading={props.loading}>
            <SendIcon className="w-10 h-10 p-2 sm:hidden" alt="" />
            <span className="hidden px-6 py-3 uppercase sm:block">
              {t("buttonSubmit")}
            </span>
          </ProgressButton>
        </div>
      </div>
    </div>
  )
}
