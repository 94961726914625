import React from "react"
import BenefitsImage from "../images/benefits.webp"
import BenefitsBgImage from "../images/svg/benefits_bg_blob.svg"
import BenefitsBlobImage from "../images/svg/benefits_blob_small.svg"
import AppContext from "../context/app_context"
import Benefit from "./benefit"
import { useTranslation } from "react-i18next"

export default function BenefitsSection() {
  const { t } = useTranslation()
  const benefits = {
    adopters: [
      t("benefitAdopter1"),
      t("benefitAdopter2"),
      t("benefitAdopter3"),
      t("benefitAdopter4"),
      t("benefitAdopter5"),
    ],
    organization: [
      t("benefitOrganization1"),
      t("benefitOrganization2"),
      t("benefitOrganization3"),
      t("benefitOrganization4"),
      t("benefitOrganization5"),
    ],
  }
  return (
    <AppContext.Consumer>
      {state => (
        <div className="overflow-hidden bg-gradient-to-b from-neutral90 ">
          <div className="flex flex-col items-center mx-auto md:flex-row md:max-w-screen-md lg:max-w-screen-lg py-14">
            <div className="w-full px-4 md:w-1/2">
              <div className="h-0.5 w-8 bg-primary20 mb-8"></div>
              <h3 className="text-section">
                <div className="transition-opacity">
                  {t("benefitsTitleFirstPartAdopter")}
                </div>
                <span className="font-bold">
                  {t("benefitsTitleSecondPartAdopter")}
                </span>
              </h3>
              <div className="max-w-sm pt-8 space-y-4 sm:max-w-md">
                {(state.isOrganizationView
                  ? benefits.organization
                  : benefits.adopters
                ).map((benefit, index) => (
                  <Benefit title={benefit} key={index} />
                ))}
              </div>
            </div>
            <div className="relative w-full max-w-md sm:max-w-lg md:w-1/2 pt-14">
              <BenefitsBgImage
                className="absolute inset-x-0 inset-y-0 mx-auto my-auto"
                alt=""
              />
              <BenefitsBlobImage
                className={`invisible sm:visible absolute bottom-1/4`}
                alt=""
              />
              <img 
               width="1398"
               height="1932"
              className="relative" src={BenefitsImage} alt="" />
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  )
}
