import React from "react"
import AppContext from "../context/app_context"
import NewsletterForm from "./newsletter_form"
import BlobLeft from "../images/svg/bottom_blob_left.svg"
import BlobRight from "../images/svg/bottom_blob_right.svg"
import Dog from "../images/svg/dog.svg"
import { useTranslation } from "react-i18next"

export default function NewsletterSection() {
  const { t } = useTranslation()
  return (
    <AppContext.Consumer>
      {state => (
        <div className="relative py-10 overflow-hidden bg-neutral90 md:py-20">
          <BlobRight
            className={`absolute -right-12 top-12 w-24 h-24 hidden md:block`}
            alt=""
          />
          <BlobLeft
            className={`absolute bottom-24 -left-12 w-24 h-24 hidden md:block`}
            alt=""
          />
          <Dog
            className={`absolute -right-12 bottom-0 hidden md:block`}
            alt=""
          />
          <div className="px-1 mx-auto md:w-3/4 lg:max-w-screen-lg">
            <div className="flex flex-col items-center px-4">
              <div className="h-0.5 w-8 bg-primary20 mb-8"></div>
              <h3 className="text-section">
                <div className="text-center transition-opacity">
                  {t("newsletterTitleFirstPartAdopter")}
                  <span className="font-bold">
                    {" "}
                    {t("newsletterTitleSecondPartAdopter")}
                  </span>
                </div>
              </h3>
              <p className="pt-8 text-center text-description md:w-3/4 ">
                {t("newsletterDescription")}
              </p>
              <div className="w-full max-w-sm pt-12 md:w-4/5 ">
                <NewsletterForm />
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  )
}
