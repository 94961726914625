import React from "react"
import FeaturesSection from "../components/features_section"
import BenefitsSection from "../components/benefits_section"
import Layout from "../components/layout"
import TopSection from "../components/top_section"
import NewsletterSection from "../components/newsletter_section"
import SEO from "../components/seo"
export default function Home() {
  return (
    <Layout>
      <SEO />
      <TopSection />
      <FeaturesSection />
      <BenefitsSection />
      <NewsletterSection />
    </Layout>
  )
}
