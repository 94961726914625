import React from "react"
import BlobIconBg from "../images/svg/blob_icon.svg"

export default function BlobIcon(props) {
  return (
    <div className="relative inline-flex">
      <BlobIconBg className="" alt="" />
      <div className="absolute inset-0 flex items-center justify-center text-3xl text-center">
        {props.icon}
      </div>
    </div>
  )
}
