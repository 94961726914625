import React from "react"
import BlobIcon from "./blob_icon"

export default function Feature(props) {
  return (
    <div className="relative">
      <div className="absolute -top-6 -left-24">
        <BlobIcon icon={props.icon} />
      </div>
      <div className="relative">
        <h4 className="text-base font-medium tracking-wide sm:text-lg lg:text-2xl">
          {props.title}
        </h4>
        <p className="pt-2 text-sm leading-7 tracking-wide lg:text-base text-neutral20">
          {props.description}
        </p>
      </div>
    </div>
  )
}
