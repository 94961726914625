import React, { useState } from "react"
import Email from "./email"
import axios from "axios"
import AppContext from "../context/app_context"
import * as Yup from "yup"
import { Formik, Field, Form } from "formik"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

export default function NewsletterForm() {
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (fields, state, resetForm) => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha("homepage")
    const email = fields.email

    const data = JSON.stringify({ email, token })
    const axiosOptions = {
      url: `${process.env.SUBSCRIBE_URL}`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }
    setLoading(true)
    axios(axiosOptions)
      .then(response => {
        resetForm({ values: "" })
        setLoading(false)
        state.openThanksDialog();
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { t } = useTranslation()
  return (
    <AppContext.Consumer>
      {state => (
        <Formik
          initialValues={{
            acceptTerms: false,
            email: "",
          }}
          validationSchema={Yup.object().shape({
            acceptTerms: Yup.bool().oneOf([true]),
            email: Yup.string().email().required("Required"),
          })}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, state, resetForm)
          }}
        >
          {({ errors, status, touched }) => (
            <Form>
              <Email error={errors.email && touched.email} loading={loading} />
              <div>
                <div className="px-4 pt-4">
                  <label className="flex items-center">
                    <Field
                      type="checkbox"
                      name="acceptTerms"
                      className={`${
                        errors.acceptTerms && touched.acceptTerms
                          ? "border-red-400"
                          : "border-neutral60"
                      } rounded ring-0 focus:ring-0 text-primary40`}
                    />
                    <span
                      className={`${
                        errors.acceptTerms && touched.acceptTerms
                          ? "text-red-400"
                          : "text-neutral20"
                      } ml-2 text-xs `}
                    >
                      {t("termsFirstPart")}
                      <Link
                        className={`underline hover:text-primary40`}
                        to="/privacy"
                      >
                        {t("termsSecondPart")}
                      </Link>
                    </span>
                  </label>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </AppContext.Consumer>
  )
}
