import { Transition } from "@headlessui/react"
import React from "react"

export default function CrossFade(props) {
  const showFirst = props.showFirst ?? true
  return (
    <div className="grid">
      <Transition
        show={showFirst}
        className="col-span-one row-span-one"
        enter="transition transform ease-out duration-300 "
        enterFrom={props.enterFrom ?? `opacity-0 -translate-y-4`}
        enterTo={props.enterTo ?? `opacity-100 translate-y-0`}
      >
        {props.firstChild}
      </Transition>
      <Transition
        show={!showFirst}
        className="col-span-one row-span-one"
        enter="transition transform ease-out duration-300 "
        enterFrom={props.enterFrom ?? `opacity-0 -translate-y-4`}
        enterTo={props.enterTo ?? `opacity-100 translate-y-0`}
      >
        {props.secondChild}
      </Transition>
    </div>
  )
}
