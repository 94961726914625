import React from "react"
import AppContext from "../context/app_context"
import Feature from "./feature"
import { useTranslation } from "react-i18next"

export default function FeaturesSection() {
  const { t } = useTranslation()
  return (
    <AppContext.Consumer>
      {state => (
        <div>
          <div className="flex flex-wrap mx-auto md:max-w-screen-md lg:max-w-screen-lg">
            <div className="w-full px-4 md:w-1/2">
              <div className="h-0.5 w-8 bg-primary20 mb-8"></div>
              <h3 className="text-section">
                <div className="transition-opacity">
                  {t("functionalitiesFirstPart")}
                </div>
                <span className="font-bold">
                  {t("functionalitiesSecondPart")}
                </span>
              </h3>
              <p className="pt-8 text-description">
                {t("functionalitiesDescription")}
              </p>
            </div>
            <div className="w-full px-2 pl-24 space-y-24 md:w-1/2 py-14">
              <div className="-ml-6 sm:ml-0">
                <Feature
                  title={
                    !state.isOrganizationView
                      ? t("firstFeatureTitleAdopter")
                      : t("firstFeatureTitleOrganization")
                  }
                  description={
                    !state.isOrganizationView
                      ? t("firstFeatureDescriptionAdopter")
                      : t("firstFeatureDescriptionOrganization")
                  }
                  icon={state.isOrganizationView ? `🏠` : `🐕`}
                />
              </div>
              <div className="-ml-6 md:ml-20 md:pl-0">
                <Feature
                  title={
                    !state.isOrganizationView
                      ? t("secondFeatureTitleAdopter")
                      : t("secondFeatureTitleOrganization")
                  }
                  description={
                    !state.isOrganizationView
                      ? t("secondFeatureDescriptionAdopter")
                      : t("secondFeatureDescriptionOrganization")
                  }
                  icon={state.isOrganizationView ? `🤝` : `🔍️`}
                />
              </div>
              <div className="-ml-6 sm:ml-0">
                <Feature
                  title={
                    !state.isOrganizationView
                      ? t("thirdFeatureTitleAdopter")
                      : t("thirdFeatureTitleOrganization")
                  }
                  description={
                    !state.isOrganizationView
                      ? t("thirdFeatureDescriptionAdopter")
                      : t("thirdFeatureDescriptionOrganization")
                  }
                  icon={state.isOrganizationView ? `🌍` : `🏡`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  )
}
