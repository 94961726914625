import React from "react"
import WomanWithDog from "../images/svg/woman_with_dog.svg"
import WomanWithNote from "../images/svg/woman_with_note.svg"

import BlobBgTop from "../images/svg/blob_bg_top.svg"
import BlobTop from "../images/svg/blob_top.svg"
import AppContext from "../context/app_context"
import NewsletterForm from "./newsletter_form"
import { useTranslation } from "react-i18next"
import CrossFade from "./crossfade"
import { StaticImage } from "gatsby-plugin-image"

export default function TopSection() {
  const { t } = useTranslation()
  return (
    <AppContext.Consumer>
      {state => (
        <div className="mb-12 md:mb-24 bg-gradient-to-b from-neutral90">
          <section className="mx-auto md:max-w-screen-md lg:max-w-screen-lg">
            <div className="pt-32 sm:pt-20">
              <div className="relative flex flex-col items-center md:flex-row">
                <BlobTop
                  className={`absolute -right-12 w-24 h-24 mt-6 md:hidden`}
                  alt=""
                />
                <div className="w-full px-4 md:w-1/2 md:mb-8">
                  <div className="flex flex-col pt-12 md:pt-16 lg:pt-20">
                    <CrossFade
                      showFirst={state.isOrganizationView}
                      firstChild={
                        <h1 className="text-3xl lg:text-6xl">
                          <div>{t("topSectionTitleFirstPartOrganization")}</div>
                          <span className="font-bold">
                            {t("topSectionTitleSecondPartOrganization")}
                          </span>
                        </h1>
                      }
                      secondChild={
                        <h1 className="text-3xl lg:text-6xl">
                          <div>{t("topSectionTitleFirstPartAdopter")}</div>
                          <span className="font-bold">
                            {t("topSectionTitleSecondPartAdopter")}
                          </span>
                        </h1>
                      }
                    />
                    <p className="max-w-lg pt-8 text-description text-neutral20">
                      {state.isOrganizationView
                        ? t("topSectionDescriptionOrganization")
                        : t("topSectionDescriptionAdopter")}
                    </p>
                    <div className="w-full pt-6 sm:max-w-sm">
                      <NewsletterForm />
                    </div>
                  </div>
                </div>
                <div className="relative w-full max-w-lg px-2 py-8 overflow-hidden md:w-1/2 sm:overflow-visible">
                  <BlobBgTop
                    className="absolute md:w-3/4 -right-14 top-16"
                    alt=""
                  />
                  <svg
                    className={`absolute transition-colors duration-300 hidden w-24 h-24 mt-2 fill-current -right-10 md:block ${
                      !state.isOrganizationView
                        ? "text-quaternary60"
                        : "text-tertiary60"
                    }`}
                  >
                    <path d="M41.752.097c-7.925.885-11.94 9.7-18.049 14.843-5.527 4.653-12.229 7.653-16.003 13.823C3.106 36.273-.868 44.668.165 53.419 1.313 63.152 5.5 73.256 13.598 78.74c8.013 5.429 18.684 4.807 28.154 2.851 8.205-1.693 13.933-8.197 20.831-12.964 7.138-4.932 18.4-6.884 20.2-15.39 1.829-8.635-8.402-14.796-12.115-22.8C67.603 23.83 68 15.82 62.98 10.551 57.396 4.691 49.783-.801 41.752.097z" />
                  </svg>

                  <div className="absolute bottom-0 w-2/3 h-auto mb-12 ml-4">
                    <CrossFade
                      showFirst={!state.isOrganizationView}
                      enterFrom="opacity-0 translate-x-4"
                      enterTo="opacity-100 translate-x-0"
                      firstChild={<WomanWithDog />}
                      secondChild={<WomanWithNote />}
                    />
                  </div>
                  <div className="relative top-0 right-0 float-right w-1/2">
                    <CrossFade
                      showFirst={!state.isOrganizationView}
                      firstChild={
                        <StaticImage
                          src="../images/top_section_image.webp"
                          width={400}
                          placeholder="none"
                          alt="A woman with a dog"
                        />
                      }
                      secondChild={
                        <StaticImage
                          src="../images/top_section_org_image.webp"
                          placeholder="none"
                          width={400}

                          alt="A woman with a note"
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </AppContext.Consumer>
  )
}
