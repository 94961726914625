import React from "react"
import CheckmarkIcon from "../images/svg/icon_checkmark.svg"

export default function Benefit(props) {
  return (
    <div className="flex flex-row">
      <div className="w-8 h-8">
      <CheckmarkIcon alt="Benefit checkmark icon" />
      </div>
      <p className="pl-4 text-description">
        {props.title}
      </p>
    </div>
  )
}
